import get from 'lodash/get';
import set from 'lodash/set';
import {
	TCollapsedSideMenuGroupTypes,
	TOnboardingGuideModulesStatus,
	TUserSettings,
	TUserSideMenuItem,
} from 'shared/api/auth.service';
import { ACCESS_LEVEL_TYPES } from 'shared/api/users.service';
import { SIDE_MENU_ITEM_TYPES } from 'shared/enums/sideMenuItemTypes';
import { TUserPermissionsType } from 'shared/types/userPermissionsTypes';

import { BasicModel } from './basic-model';

interface PropertyResolver {
	property: string;
	path?: string;
	deserialize?: (data: any) => any;
}

type TMenuItemSettings = {
	isActive: boolean;
	name: string;
};

const propertyResolvers: PropertyResolver[] = [
	{ property: 'accountStatus', path: 'accountStatus' },
	{ property: 'address.address', path: 'address.address' },
	{ property: 'address.city', path: 'address.city' },
	{ property: 'address.country', path: 'address.country' },
	{ property: 'address.usaStateType', path: 'address.usaStateType' },
	{ property: 'address.zipCode', path: 'address.zipCode' },
	{ property: 'email', path: 'email' },
	{ property: 'firstName', path: 'firstName' },
	{ property: 'functions', path: 'functions' },
	{ property: 'id', path: 'id' },
	{ property: 'jobTitle', path: 'jobTitle' },
	{ property: 'lastName', path: 'lastName' },
	{ property: 'onboardedAt', path: 'onboardedAt' },
	{ property: 'currentEngagement', path: 'currentEngagement' },
	{ property: 'engagementIds', path: 'engagementIds' },
	{ property: 'accessLevelType', path: 'accessLevelType' },
	{ property: 'userSettings', path: 'userSettings' },

	//user settings - hasSignedInToJira
	{ property: 'userSettings.hasSignedInToJira', path: 'userSettings.hasSignedInToJira' },

	//user settings - hasSignedInToZoom
	{ property: 'userSettings.hasSignedInToZoom', path: 'userSettings.hasSignedInToZoom' },

	//user settings - onboardingGuides
	{ property: 'userSettings.onboardingGuides', path: 'userSettings.onboardingGuides' },

	//user settings - sideMenuItems
	{ property: 'userSettings.sideMenuItems', path: 'userSettings.sideMenuItems' },

	//user settings - calendarId
	{ property: 'userSettings.calendarId', path: 'userSettings.calendarId' },

	//user settings - isVoiceChatActive
	{ property: 'userSettings.isVoiceChatActive', path: 'userSettings.isVoiceChatActive' },

	//billingManagement
	{
		property: 'userPermissions.billingManagement',
		path: 'userPermissions.billingManagement',
	},

	// organizationManagement
	{
		property: 'userPermissions.organizationManagement.list',
		path: 'userPermissions.organizationManagement.list',
	},
	{
		property: 'userPermissions.organizationManagement.edit',
		path: 'userPermissions.organizationManagement.edit',
	},
	// engagementManagement
	{
		property: 'userPermissions.engagementManagement.list',
		path: 'userPermissions.engagementManagement.list',
	},
	{
		property: 'userPermissions.engagementManagement.edit',
		path: 'userPermissions.engagementManagement.edit',
	},
	{
		property: 'userPermissions.engagementManagement.create',
		path: 'userPermissions.engagementManagement.create',
	},
	{
		property: 'userPermissions.engagementManagement.delete',
		path: 'userPermissions.engagementManagement.delete',
	},

	// diagrams
	{
		property: 'userPermissions.diagrams.list',
		path: 'userPermissions.diagrams.list',
	},
	{
		property: 'userPermissions.diagrams.edit',
		path: 'userPermissions.diagrams.edit',
	},
	{
		property: 'userPermissions.diagrams.create',
		path: 'userPermissions.diagrams.create',
	},
	{
		property: 'userPermissions.diagrams.delete',
		path: 'userPermissions.diagrams.delete',
	},

	// fileExplorer
	{
		property: 'userPermissions.fileExplorer.list',
		path: 'userPermissions.fileExplorer.list',
	},
	{
		property: 'userPermissions.fileExplorer.edit',
		path: 'userPermissions.fileExplorer.edit',
	},
	{
		property: 'userPermissions.fileExplorer.create',
		path: 'userPermissions.fileExplorer.create',
	},
	{
		property: 'userPermissions.fileExplorer.delete',
		path: 'userPermissions.fileExplorer.delete',
	},

	// storboards
	{
		property: 'userPermissions.storboards.list',
		path: 'userPermissions.storboards.list',
	},
	{
		property: 'userPermissions.storboards.edit',
		path: 'userPermissions.storboards.edit',
	},
	{
		property: 'userPermissions.storboards.create',
		path: 'userPermissions.storboards.create',
	},
	{
		property: 'userPermissions.storboards.delete',
		path: 'userPermissions.storboards.delete',
	},

	// userManagement
	{
		property: 'userPermissions.userManagement.list',
		path: 'userPermissions.userManagement.list',
	},
	{
		property: 'userPermissions.userManagement.edit',
		path: 'userPermissions.userManagement.edit',
	},
	{
		property: 'userPermissions.userManagement.adminCreation',
		path: 'userPermissions.userManagement.adminCreation',
	},
	{
		property: 'userPermissions.userManagement.collaboratorCreation',
		path: 'userPermissions.userManagement.collaboratorCreation',
	},
	{
		property: 'userPermissions.userManagement.practitionerCreation',
		path: 'userPermissions.userManagement.practitionerCreation',
	},
	{
		property: 'userPermissions.userManagement.profileExclusion',
		path: 'userPermissions.userManagement.profileExclusion',
	},

	// optimization
	{
		property: 'userPermissions.optimization.list',
		path: 'userPermissions.optimization.list',
	},
	{
		property: 'userPermissions.optimization.edit',
		path: 'userPermissions.optimization.edit',
	},
	{
		property: 'userPermissions.optimization.create',
		path: 'userPermissions.optimization.create',
	},
	{
		property: 'userPermissions.optimization.delete',
		path: 'userPermissions.optimization.delete',
	},

	//  retrospectives page
	{
		property: 'userPermissions.retrospectives.list',
		path: 'userPermissions.retrospectives.list',
	},
	{
		property: 'userPermissions.retrospectives.edit',
		path: 'userPermissions.retrospectives.edit',
	},
	{
		property: 'userPermissions.retrospectives.create',
		path: 'userPermissions.retrospectives.create',
	},
	{
		property: 'userPermissions.retrospectives.delete',
		path: 'userPermissions.retrospectives.delete',
	},

	// projects - portfoliosPage
	{
		property: 'userPermissions.projects.portfoliosPage.list',
		path: 'userPermissions.projects.portfoliosPage.list',
	},
	{
		property: 'userPermissions.projects.portfoliosPage.edit',
		path: 'userPermissions.projects.portfoliosPage.edit',
	},
	{
		property: 'userPermissions.projects.portfoliosPage.create',
		path: 'userPermissions.projects.portfoliosPage.create',
	},
	{
		property: 'userPermissions.projects.portfoliosPage.delete',
		path: 'userPermissions.projects.portfoliosPage.delete',
	},

	//  projects - programsPage
	{
		property: 'userPermissions.projects.programsPage.list',
		path: 'userPermissions.projects.programsPage.list',
	},
	{
		property: 'userPermissions.projects.programsPage.edit',
		path: 'userPermissions.projects.programsPage.edit',
	},
	{
		property: 'userPermissions.projects.programsPage.create',
		path: 'userPermissions.projects.programsPage.create',
	},
	{
		property: 'userPermissions.projects.programsPage.delete',
		path: 'userPermissions.projects.programsPage.delete',
	},

	// projects - timeline
	{
		property: 'userPermissions.projects.timeline.list',
		path: 'userPermissions.projects.timeline.list',
	},
	{
		property: 'userPermissions.projects.timeline.edit',
		path: 'userPermissions.projects.timeline.edit',
	},
	{
		property: 'userPermissions.projects.timeline.create',
		path: 'userPermissions.projects.timeline.create',
	},
	{
		property: 'userPermissions.projects.timeline.delete',
		path: 'userPermissions.projects.timeline.delete',
	},
	// projects - portfolioDashboard
	{
		property: 'userPermissions.projects.portfolioDashboard.list',
		path: 'userPermissions.projects.portfolioDashboard.list',
	},
	{
		property: 'userPermissions.projects.portfolioDashboard.edit',
		path: 'userPermissions.projects.portfolioDashboard.edit',
	},
	{
		property: 'userPermissions.projects.portfolioDashboard.create',
		path: 'userPermissions.projects.portfolioDashboard.create',
	},
	{
		property: 'userPermissions.projects.portfolioDashboard.delete',
		path: 'userPermissions.projects.portfolioDashboard.delete',
	},

	// projects - portfolioGuidance
	{
		property: 'userPermissions.projects.portfolioGuidance.list',
		path: 'userPermissions.projects.portfolioGuidance.list',
	},
	{
		property: 'userPermissions.projects.portfolioGuidance.edit',
		path: 'userPermissions.projects.portfolioGuidance.edit',
	},
	{
		property: 'userPermissions.projects.portfolioGuidance.create',
		path: 'userPermissions.projects.portfolioGuidance.create',
	},
	{
		property: 'userPermissions.projects.portfolioGuidance.delete',
		path: 'userPermissions.projects.portfolioGuidance.delete',
	},

	// projects - projectsPage
	{
		property: 'userPermissions.projects.projectsPage.list',
		path: 'userPermissions.projects.projectsPage.list',
	},
	{
		property: 'userPermissions.projects.projectsPage.edit',
		path: 'userPermissions.projects.projectsPage.edit',
	},
	{
		property: 'userPermissions.projects.projectsPage.create',
		path: 'userPermissions.projects.projectsPage.create',
	},
	{
		property: 'userPermissions.projects.projectsPage.delete',
		path: 'userPermissions.projects.projectsPage.delete',
	},

	// Digital Whiteboards
	{
		property: 'userPermissions.digitalDiagramsPage.list',
		path: 'userPermissions.digitalDiagramsPage.list',
	},
	{
		property: 'userPermissions.digitalDiagramsPage.edit',
		path: 'userPermissions.digitalDiagramsPage.edit',
	},
	{
		property: 'userPermissions.digitalDiagramsPage.create',
		path: 'userPermissions.digitalDiagramsPage.create',
	},
	{
		property: 'userPermissions.digitalDiagramsPage.delete',
		path: 'userPermissions.digitalDiagramsPage.delete',
	},

	// ActionPlans Page
	{
		property: 'userPermissions.actionPlan.list',
		path: 'userPermissions.actionPlan.list',
	},
	{
		property: 'userPermissions.actionPlan.edit',
		path: 'userPermissions.actionPlan.edit',
	},
	{
		property: 'userPermissions.actionPlan.create',
		path: 'userPermissions.actionPlan.create',
	},
	{
		property: 'userPermissions.actionPlan.delete',
		path: 'userPermissions.actionPlan.delete',
	},

	// operatingModel Page
	{
		property: 'userPermissions.operatingModel.list',
		path: 'userPermissions.operatingModel.list',
	},
	{
		property: 'userPermissions.operatingModel.edit',
		path: 'userPermissions.operatingModel.edit',
	},
	{
		property: 'userPermissions.operatingModel.create',
		path: 'userPermissions.operatingModel.create',
	},
	{
		property: 'userPermissions.operatingModel.delete',
		path: 'userPermissions.operatingModel.delete',
	},

	// forecast
	{
		property: 'userPermissions.forecast.list',
		path: 'userPermissions.forecast.list',
	},
	{
		property: 'userPermissions.forecast.edit',
		path: 'userPermissions.forecast.edit',
	},
	{
		property: 'userPermissions.forecast.create',
		path: 'userPermissions.forecast.create',
	},
	{
		property: 'userPermissions.forecast.delete',
		path: 'userPermissions.forecast.delete',
	},

	//Plan
	{
		property: 'userPermissions.plan.list',
		path: 'userPermissions.plan.list',
	},
	{
		property: 'userPermissions.plan.edit',
		path: 'userPermissions.plan.edit',
	},
	{
		property: 'userPermissions.plan.create',
		path: 'userPermissions.plan.create',
	},
	{
		property: 'userPermissions.plan.delete',
		path: 'userPermissions.plan.delete',
	},

	//Industry Reports
	{
		property: 'userPermissions.industryReports.list',
		path: 'userPermissions.industryReports.list',
	},
	{
		property: 'userPermissions.industryReports.edit',
		path: 'userPermissions.industryReports.edit',
	},
	{
		property: 'userPermissions.industryReports.create',
		path: 'userPermissions.industryReports.create',
	},
	{
		property: 'userPermissions.industryReports.delete',
		path: 'userPermissions.industryReports.delete',
	},
	//Process Reports
	{
		property: 'userPermissions.processReports.list',
		path: 'userPermissions.processReports.list',
	},
	{
		property: 'userPermissions.processReports.edit',
		path: 'userPermissions.processReports.edit',
	},
	{
		property: 'userPermissions.processReports.create',
		path: 'userPermissions.processReports.create',
	},
	{
		property: 'userPermissions.processReports.delete',
		path: 'userPermissions.processReports.delete',
	},
	//Workspaces
	{
		property: 'userPermissions.workspaces.list',
		path: 'userPermissions.workspaces.list',
	},
	{
		property: 'userPermissions.workspaces.edit',
		path: 'userPermissions.workspaces.edit',
	},
	{
		property: 'userPermissions.workspaces.create',
		path: 'userPermissions.workspaces.create',
	},
	{
		property: 'userPermissions.workspaces.delete',
		path: 'userPermissions.workspaces.delete',
	},
];

class UserModel implements BasicModel {
	accessLevelType: ACCESS_LEVEL_TYPES;
	id: string;
	currentEngagement: {
		id: string;
		name: string;
	};
	address: {
		address: string;
		city: string;
		country: string;
		usaStateType: string;
		zipCode: string;
	};
	engagementIds: string[];
	onboardedAt: string;
	accountStatus: string;
	jobTitle: string;
	email: string;
	firstName: string;
	lastName: string;
	userPermissions: TUserPermissionsType;
	userSettings: TUserSettings;

	constructor(values: any) {
		if (values) {
			this.deserialize(values);
		}
	}

	get fullName(): string | undefined {
		if (this.firstName && this.lastName) {
			return `${this.firstName} ${this.lastName}`;
		}
	}

	get initials(): string | undefined {
		if (this.firstName && this.lastName) {
			return `${this.firstName.at(0)}${this.lastName.at(0)}`.toUpperCase();
		}
	}

	get isInstanceOwner(): boolean {
		return this.accessLevelType === ACCESS_LEVEL_TYPES.OWNER;
	}
	get isTeamAdmin(): boolean {
		return this.accessLevelType === ACCESS_LEVEL_TYPES.TEAM_ADMIN;
	}

	// user settings
	get sideMenuItems(): TUserSideMenuItem[] {
		return this.userSettings.sideMenuItems || [];
	}
	get isVoiceChatActive(): boolean {
		return this.userSettings.isVoiceChatActive;
	}
	get onboardingGuides(): TOnboardingGuideModulesStatus {
		return this.userSettings.onboardingGuides;
	}
	get collapsedSideMenuGroupTypes(): TCollapsedSideMenuGroupTypes {
		return this.userSettings.collapsedSideMenuGroupTypes;
	}
	get hasSignedInToJira(): boolean {
		return this.userSettings.hasSignedInToJira;
	}
	get hasSignedInToZoom(): boolean {
		return this.userSettings.hasSignedInToZoom;
	}

	get isSideMenuItemStoryboardSettings(): TMenuItemSettings | undefined {
		const activeStoryboardSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.STORYBOARD,
		);
		return (
			activeStoryboardSideMenuItem && {
				isActive: !!activeStoryboardSideMenuItem.isActive,
				name: activeStoryboardSideMenuItem.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemPlanSettings(): TMenuItemSettings | undefined {
		const activePlanSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.PLAN,
		);

		return (
			activePlanSideMenuItem && {
				isActive: !!activePlanSideMenuItem?.isActive,
				name: activePlanSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemForecastSettings(): TMenuItemSettings | undefined {
		const forecastSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.FORECAST,
		);

		return (
			forecastSideMenuItem && {
				isActive: !!forecastSideMenuItem?.isActive,
				name: forecastSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemOperatingModelSettings(): TMenuItemSettings | undefined {
		const operatingModelSideMenuItem = this.sideMenuItems.find(
			(item) =>
				item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.OPERATING_MODEL,
		);

		return (
			operatingModelSideMenuItem && {
				isActive: !!operatingModelSideMenuItem?.isActive,
				name: operatingModelSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemProjectsSettings(): TMenuItemSettings | undefined {
		const projectsSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.PROJECTS,
		);

		return (
			projectsSideMenuItem && {
				isActive: !!projectsSideMenuItem?.isActive,
				name: projectsSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemActionPlanSettings(): TMenuItemSettings | undefined {
		const actionPlanSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.ACTION_PLAN,
		);

		return (
			actionPlanSideMenuItem && {
				isActive: !!actionPlanSideMenuItem?.isActive,
				name: actionPlanSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemRetrospectivesSettings(): TMenuItemSettings | undefined {
		const retrospectivesSideMenuItem = this.sideMenuItems.find(
			(item) =>
				item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.RETROSPECTIVES,
		);

		return (
			retrospectivesSideMenuItem && {
				isActive: !!retrospectivesSideMenuItem?.isActive,
				name: retrospectivesSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemOptimizationSettings(): TMenuItemSettings | undefined {
		const optimizationSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.OPTIMIZATION,
		);

		return (
			optimizationSideMenuItem && {
				isActive: !!optimizationSideMenuItem?.isActive,
				name: optimizationSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemDiagramsSettings(): TMenuItemSettings | undefined {
		const diagramsSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.DIAGRAMS,
		);

		return (
			diagramsSideMenuItem && {
				isActive: !!diagramsSideMenuItem?.isActive,
				name: diagramsSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemFileExplorerSettings(): TMenuItemSettings | undefined {
		const fileExplorerSideMenuItem = this.sideMenuItems.find(
			(item) =>
				item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.FILE_EXPLORER,
		);

		return (
			fileExplorerSideMenuItem && {
				isActive: !!fileExplorerSideMenuItem?.isActive,
				name: fileExplorerSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemIndustryReportsSettings(): TMenuItemSettings | undefined {
		const industryReportsSideMenuItem = this.sideMenuItems.find(
			(item) =>
				item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.INDUSTRY_REPORTS,
		);

		return (
			industryReportsSideMenuItem && {
				isActive: !!industryReportsSideMenuItem?.isActive,
				name: industryReportsSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemProcessReportsSettings(): TMenuItemSettings | undefined {
		const processReportsSideMenuItem = this.sideMenuItems.find(
			(item) =>
				item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.PROCESS_REPORTS,
		);

		return (
			processReportsSideMenuItem && {
				isActive: !!processReportsSideMenuItem?.isActive,
				name: processReportsSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	get isSideMenuItemWorkspacesSettings(): TMenuItemSettings | undefined {
		const workspacesSideMenuItem = this.sideMenuItems.find(
			(item) => item.applicationModule?.applicationModuleType === SIDE_MENU_ITEM_TYPES.WORKSPACES,
		);

		return (
			workspacesSideMenuItem && {
				isActive: !!workspacesSideMenuItem?.isActive,
				name: workspacesSideMenuItem?.applicationModule?.name || '',
			}
		);
	}

	// billingManagement
	get isCanBeViewBillingManagement(): boolean {
		return this.userPermissions.billingManagement;
	}

	// organizationManagement
	get isCanBeViewOrganizationManagement(): boolean {
		return this.userPermissions.organizationManagement?.list;
	}
	get isCanBeEditOrganizationManagement(): boolean {
		return this.userPermissions.organizationManagement?.edit;
	}

	// engagementManagement
	get isCanBeViewEngagementManagement(): boolean {
		return this.userPermissions.engagementManagement?.list;
	}

	get isCanBeEditEngagementManagement(): boolean {
		return this.userPermissions.engagementManagement?.edit;
	}

	get isCanBeCreateEngagementManagement(): boolean {
		return this.userPermissions.engagementManagement?.create;
	}

	get isCanBeDeleteEngagementManagement(): boolean {
		return this.userPermissions.engagementManagement?.delete;
	}

	// fileExplorer
	get isCanBeViewFileExplorerPage(): boolean {
		return this.userPermissions.fileExplorer?.list;
	}

	get isCanBeEditFileExplorerPage(): boolean {
		return this.userPermissions.fileExplorer?.edit;
	}

	get isCanBeCreateFileExplorerPage(): boolean {
		return this.userPermissions.fileExplorer?.create;
	}

	get isCanBeDeleteFileExplorerPage(): boolean {
		return this.userPermissions.fileExplorer?.delete;
	}

	// storboards
	get isCanBeViewStoryBoards(): boolean {
		return this.userPermissions.storboards?.list;
	}

	get isCanBeEditStoryBoards(): boolean {
		return this.userPermissions.storboards?.edit;
	}

	get isCanBeCreateStoryBoards(): boolean {
		return this.userPermissions.storboards?.create;
	}

	get isCanBeDeleteStoryBoards(): boolean {
		return this.userPermissions.storboards?.delete;
	}

	// userManagement
	get isCanBeViewUserManagement(): boolean {
		return this.userPermissions.userManagement?.list;
	}

	get isCanBeEditUserManagement(): boolean {
		return this.userPermissions.userManagement?.edit;
	}

	get isCanBeAdminCreationUserManagement(): boolean {
		return this.userPermissions.userManagement?.adminCreation;
	}

	get isCanBeCollaboratorCreationUserManagement(): boolean {
		return this.userPermissions.userManagement?.collaboratorCreation;
	}

	get isCanBePractitionerCreationUserManagement(): boolean {
		return this.userPermissions.userManagement?.practitionerCreation;
	}

	get profileExclusionUserManagement(): string[] {
		return this.userPermissions.userManagement?.profileExclusion;
	}

	// optimization
	get isCanBeViewOptimizationPage(): boolean {
		return this.userPermissions.optimization?.list;
	}

	get isCanBeEditOptimizationPage(): boolean {
		return this.userPermissions.optimization?.edit;
	}

	get isCanBeCreateOptimizationPage(): boolean {
		return this.userPermissions.optimization?.create;
	}

	get isCanBeDeleteOptimizationPage(): boolean {
		return this.userPermissions.optimization?.delete;
	}

	// projects - portfoliosPage
	get isCanBeViewPortfoliosPage(): boolean {
		return this.userPermissions?.projects?.portfoliosPage?.list;
	}

	get isCanBeEditPortfoliosPage(): boolean {
		return this.userPermissions?.projects?.portfoliosPage?.edit;
	}

	get isCanBeCreatePortfoliosPage(): boolean {
		return this.userPermissions?.projects?.portfoliosPage?.create;
	}

	get isCanBeDeletePortfoliosPage(): boolean {
		return this.userPermissions?.projects?.portfoliosPage?.delete;
	}

	// projects - programsPage
	get isCanBeViewProgramsPage(): boolean {
		return this.userPermissions?.projects?.programsPage?.list;
	}

	get isCanBeEditProgramsPage(): boolean {
		return this.userPermissions?.projects?.programsPage?.edit;
	}

	get isCanBeCreateProgramsPage(): boolean {
		return this.userPermissions?.projects?.programsPage?.create;
	}

	get isCanBeDeleteProgramsPage(): boolean {
		return this.userPermissions?.projects?.programsPage?.delete;
	}

	//  projects - timeline
	get isCanBeViewProjectsTimelinePage(): boolean {
		return this.userPermissions?.projects?.timeline?.list;
	}

	get isCanBeEditProjectsTimelinePage(): boolean {
		return this.userPermissions?.projects?.timeline?.edit;
	}

	get isCanBeCreateProjectsTimelinePage(): boolean {
		return this.userPermissions?.projects?.timeline?.create;
	}

	get isCanBeDeleteProjectsTimelinePage(): boolean {
		return this.userPermissions?.projects?.timeline?.delete;
	}

	//  projects - portfolioDashboard
	get isCanBeViewPortfolioDashboardPage(): boolean {
		return this.userPermissions?.projects?.portfolioDashboard?.list;
	}

	get isCanBeEditPortfolioDashboardPage(): boolean {
		return this.userPermissions?.projects?.portfolioDashboard?.edit;
	}

	get isCanBeCreatePortfolioDashboardPage(): boolean {
		return this.userPermissions?.projects?.portfolioDashboard?.create;
	}

	get isCanBeDeletePortfolioDashboardPage(): boolean {
		return this.userPermissions?.projects?.portfolioDashboard?.delete;
	}

	//  projects - portfolioGuidance
	get isCanBeViewPortfolioGuidancePage(): boolean {
		return this.userPermissions?.projects?.portfolioGuidance?.list;
	}

	get isCanBeEditPortfolioGuidancePage(): boolean {
		return this.userPermissions?.projects?.portfolioGuidance?.edit;
	}

	get isCanBeCreatePortfolioGuidancePage(): boolean {
		return this.userPermissions?.projects?.portfolioGuidance?.create;
	}

	get isCanBeDeletePortfolioGuidancePage(): boolean {
		return this.userPermissions?.projects?.portfolioGuidance?.delete;
	}

	//  projects - projectsPage
	get isCanBeViewProjectsPage(): boolean {
		return this.userPermissions?.projects?.projectsPage?.list;
	}

	get isCanBeEditProjectsPage(): boolean {
		return this.userPermissions?.projects?.projectsPage?.edit;
	}

	get isCanBeCreateProjectsPage(): boolean {
		return this.userPermissions?.projects?.projectsPage?.create;
	}

	get isCanBeDeleteProjectsPage(): boolean {
		return this.userPermissions?.projects?.projectsPage?.delete;
	}

	// Diagrams
	get isCanBeViewDiagramsPage(): boolean {
		return this.userPermissions.diagrams.list;
	}

	get isCanBeEditDiagramsPage(): boolean {
		return this.userPermissions.diagrams.edit;
	}

	get isCanBeCreateDiagramsPage(): boolean {
		return this.userPermissions.diagrams.create;
	}

	get isCanBeDeleteDiagramsPage(): boolean {
		return this.userPermissions.diagrams.delete;
	}

	// Industry Reports
	get isCanBeViewIndustryReportsPage(): boolean {
		return this.userPermissions.industryReports.list;
	}

	get isCanBeEditIndustryReportsPage(): boolean {
		return this.userPermissions.industryReports.edit;
	}

	get isCanBeCreateIndustryReportsPage(): boolean {
		return this.userPermissions.industryReports.create;
	}

	get isCanBeDeleteIndustryReportsPage(): boolean {
		return this.userPermissions.industryReports.delete;
	}

	// Process Reports
	get isCanBeViewProcessReportsPage(): boolean {
		return this.userPermissions.processReports.list;
	}

	get isCanBeEditProcessReportsPage(): boolean {
		return this.userPermissions.processReports.edit;
	}

	get isCanBeCreateProcessReportsPage(): boolean {
		return this.userPermissions.processReports.create;
	}

	get isCanBeDeleteProcessReportsPage(): boolean {
		return this.userPermissions.processReports.delete;
	}

	// ActionPlans Page
	get isCanBeViewActionPlansPage(): boolean {
		return this.userPermissions.actionPlan.list;
	}

	get isCanBeEditActionPlansPage(): boolean {
		return this.userPermissions.actionPlan.edit;
	}

	get isCanBeCreateActionPlansPage(): boolean {
		return this.userPermissions.actionPlan.create;
	}

	get isCanBeDeleteActionPlansPage(): boolean {
		return this.userPermissions.actionPlan.delete;
	}

	// OperatingModel Page
	get isCanBeViewOperatingModelPage(): boolean {
		return this.userPermissions.operatingModel.list;
	}

	get isCanBeEditOperatingModelPage(): boolean {
		return this.userPermissions.operatingModel.edit;
	}

	get isCanBeCreateOperatingModelPage(): boolean {
		return this.userPermissions.operatingModel.create;
	}

	get isCanBeDeleteOperatingModelPage(): boolean {
		return this.userPermissions.operatingModel.delete;
	}

	// forecast
	get isCanBeViewForecastPage(): boolean {
		return this.userPermissions.forecast.list;
	}

	get isCanBeEditForecastPage(): boolean {
		return this.userPermissions.forecast.edit;
	}

	get isCanBeCreateForecastPage(): boolean {
		return this.userPermissions.forecast.create;
	}

	get isCanBeDeleteForecastPage(): boolean {
		return this.userPermissions.forecast.delete;
	}

	// Plan
	get isCanBeViewPlanPage(): boolean {
		return this.userPermissions.plan.list;
	}

	get isCanBeEditPlanPage(): boolean {
		return this.userPermissions.plan.edit;
	}

	get isCanBeCreatePlanPage(): boolean {
		return this.userPermissions.plan.create;
	}

	get isCanBeDeletePlanPage(): boolean {
		return this.userPermissions.plan.delete;
	}

	// retrospectives page
	get isCanBeViewRetrospectivesPage(): boolean {
		return this.userPermissions?.retrospectives?.list;
	}

	get isCanBeEditRetrospectivesPage(): boolean {
		return this.userPermissions?.retrospectives?.edit;
	}

	get isCanBeCreateRetrospectivesPage(): boolean {
		return this.userPermissions?.retrospectives?.create;
	}

	get isCanBeDeleteRetrospectivesPage(): boolean {
		return this.userPermissions?.retrospectives?.delete;
	}

	// workspaces
	get isCanBeViewWorkspacesPage(): boolean {
		return this.userPermissions?.workspaces?.list;
	}

	get isCanBeEditWorkspacesPage(): boolean {
		return this.userPermissions?.workspaces?.edit;
	}

	get isCanBeCreateWorkspacesPage(): boolean {
		return this.userPermissions?.workspaces?.create;
	}

	get isCanBeDeleteWorkspacesPage(): boolean {
		return this.userPermissions?.workspaces?.delete;
	}

	// home
	get isCanBeViewHomePage(): boolean {
		return this.accessLevelType !== ACCESS_LEVEL_TYPES.SYSTEM_ADMIN;
	}

	// if you need to hide some elements for a future
	get featureToggle(): boolean {
		return false;
	}

	deserialize(values: any): void {
		propertyResolvers.forEach(({ property, deserialize, path }) => {
			if (typeof deserialize === 'function') {
				set(this, property, deserialize(values));
			} else if (typeof path !== 'undefined') {
				set(this, property, get(values, path));
			}
		});
	}
}

export default UserModel;
